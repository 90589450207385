import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doula',
  templateUrl: './doula.component.html',
  styleUrls: ['./doula.component.scss']
})
export class DoulaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
