import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BbcComponent} from './bbc/bbc.component';
import { ContactComponent} from './contact/contact.component';
import { DoulaComponent} from './doula/doula.component';
import { AssistantComponent} from './assistant/assistant.component';
import {MissingAssetSourceRootException} from '@angular-devkit/build-angular/src/utils';
import {ColorpickerComponent} from './colorpicker/colorpicker.component';
import {TrainingsComponent} from './trainings/trainings.component';
import {ClassesComponent} from './classes/classes.component';
import {ServicesComponent} from './services/services.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "home"},
  {path: "home", component: HomeComponent},
  {path: "about", component: AboutComponent},
  {path: "doula", component: DoulaComponent},
  {path: "assistant", component: AssistantComponent},
  {path: "contact", component: ContactComponent},
  {path: "bbc", component: BbcComponent},
  {path: "services", component: ServicesComponent},
  {path: "classes", component: ClassesComponent},
  {path: "trainings", component: TrainingsComponent},
  {path: "test", component: ColorpickerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
