import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hey',
  templateUrl: './hey.component.html',
  styleUrls: ['./hey.component.scss']
})
export class HeyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
